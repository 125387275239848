<template>
  <div id="report-config" class="vap-page">
    <Loading
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div class="vap-page-header">
      <h1>Workflow Report</h1>
      <div id="workflow-overlay" class="btns-container">
        <VasionButton
          v-if="false"
          id="button-favorite"
          :icon="'VasionStarIcon'"
          :isDisabled="disableGenerateResults"
          title="Create Favorite"
          @vasionButtonClicked="createFavorite()"
        />
        <VasionButton
          v-if="!editingWorkflowReport"
          id="share"
          :classProp="'secondary'"
          :isDisabled="disableGenerateResults"
          @vasionButtonClicked="exportReport('pdf')"
        >
          Share
        </VasionButton>
        <VasionButton
          v-if="!editingWorkflowReport"
          id="run-report"
          :classProp="'primary'"
          :isDisabled="disableGenerateResults"
          @vasionButtonClicked="generateReport()"
        >
          Run Report
        </VasionButton>
        <VasionButton
          id="save-report"
          class="last-btn"
          :classProp="'primary'"
          :isDisabled="disableGenerateResults"
          @vasionButtonClicked="saveReport()"
        >
          Save Report
        </VasionButton>
      </div>
    </div>
    <div class="vap-page-content">
      <table class="w100">
        <tr>
          <td class="w25">
            <!-- <md-field>
              <label for="workflows">Workflow</label>
              <md-select  name="workflows" id="workflows" v-model="selectedWorkflow">
                <md-option v-for="workflow in workflows" :key="workflow.value" :value="workflow.value">
                  {{ workflow.name }}
                </md-option>
              </md-select>
            </md-field> -->
            <VasionDropList
              v-slot="slotItem"
              v-model="selectedWorkflow"
              :dataArray="workflows"
              title="WORKFLOW"
              placeholder="Workflow"
              type="plain-list"
              valueName="value"
              displayName="name"
              width="100%"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
          </td>
          <td class="w25">
            <!-- <md-field>
              <label for="users">Approvers</label>
              <md-select  name="users" id="users" v-model="selectedUsers" multiple>
                <md-option v-for="user in users" :key="user.value" :value="user.value">
                  {{ user.name }}
                </md-option>
              </md-select>
            </md-field> -->
            <VasionDropList
              v-slot="slotItem"
              v-model="selectedUser"
              :dataArray="users"
              title="APPROVER"
              placeholder="Approver"
              type="plain-list"
              valueName="value"
              displayName="name"
              width="100%"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
          </td>
          <td class="w25">
            <VasionButton
              id="btnAddedAttributes"
              class="added-attributes-button"
              :classProp="'secondary'"
              :isFullWidth="true"
              :isDisabled="!selectedWorkflow.indexForm"
              @vasionButtonClicked="addedAttributesClick"
            >
              Added Fields
            </VasionButton>
          </td>
        </tr>
      </table>
      <table class="w100">
        <tr>
          <td class="w50">
            <VasionDateTimeField
              v-model="fromDate"
              label="FROM DATE"
              type="date"
              :required="true"
            />
          </td>
          <td class="w50">
            <VasionDateTimeField
              v-model="toDate"
              label="THROUGH DATE"
              type="date"
              :required="true"
            />
          </td>
        </tr>
      </table>
      <div v-if="workflowReport.tableHeaders">
        {{ workflowReportSummary }}
        <div
          v-if="workflowReport.tableRows.length > 0"
          id="no-results-div"
          class="results-table-container vasion-html-table-default"
          :class="{ 'expandedResultsGridDiv': mainNavExpanded, 'retractedResultsGridDiv': !mainNavExpanded }"
        >
          <VasionTable id="report-table" :headerColumns="workflowReport.tableHeaders" :tableRows="workflowReport.tableRows" />
        </div>
        <div v-else>
          No data for the given report configuration
        </div>
      </div>
      <div v-else class="vasion-run-report-prompt">
        <VasionEmptyReportsIcon />
        <div class="vasion-large-message">
          Run a Report to view entries
        </div>
      </div>
    </div>
    <md-dialog id="indexFieldFiler" :md-active.sync="showFieldDetailsDialog" :md-click-outside-to-close="false" class="attribute-fields-dialog-window ">
      <IndexFieldList
        :modalIdentifier="'indexFieldFiler'"
        :fields="indexFields"
        :selectorOnly="false"
        :showCancelButton="true"
        :showSubmit="true"
        @submitted="indexFieldFilerSubmitted"
        @submitCancelled="showFieldDetailsDialog = !showFieldDetailsDialog"
      />
    </md-dialog>

    <md-dialog id="indexFieldSelector" :md-active.sync="showExportFieldsDialog" :md-click-outside-to-close="false" class="dialog-window">
      <IndexFieldList
        :modalIdentifier="'indexFieldSelector'"
        :fields="indexFields"
        :selectorOnly="true"
        :showSubmit="true"
        :showCancelButton="true"
        @submitted="indexFieldSelectorSubmitted"
        @submitCancelled="showExportFieldsDialog = !showExportFieldsDialog"
      />
    </md-dialog>
  </div>
</template>

<script>
import IndexFieldList from '@/components/IndexFieldList.vue'
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: 'TheWorkflowReport',
  components: {
    IndexFieldList,
    Loading,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
     vm.prevRoute = from
   })
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'WorkflowReportConfig') {
      this.$store.dispatch('reporting/setEditingWorkflowReport', false)
    }
    next()
  },
  data: function () {
    return {
      exportType: '',
      fromDate: '',
      indexFields: [],
      isLoading: false,
      loaderBackgroundColor: '#ece7ec',
      loaderColor: '#ff4702',
      prevRoute: null,
      selectedUser: '',
      selectedWorkflow: '',
      showExportFieldsDialog: false,
      showFieldDetailsDialog: false,
      toDate: '',
    }
  },
  computed: {
    disableGenerateResults() { return this.toDate === '' || this.fromDate === '' },
    editingWorkflowReport() { return this.$store.state.reporting.editingWorkflowReport },
    indexFieldFilters() { return this.$store.state.reporting.indexFieldFilters },
    mainNavExpanded() { return this.$store.state.mainViews.mainNavExpanded },
    selectedIndexFieldsForExtract() {
      const columnNames = this.$store.state.common.indexFields.map(field => {
        return field.name
      })
      const uniqueColumns = [...new Set(columnNames)]

      return uniqueColumns
    },
    users() { return this.$store.state.common.usersWithAutoComplete },
    workflowReport() { return this.$store.state.reporting.workflowReport },
    workflowReportSummary() { return this.$store.state.reporting.workflowReportSummary },
    workflowReportValues() { return this.$store.state.reporting.workflowReportData },
    workflows() { return this.$store.state.reporting.workflows },
  },
  watch: {
    selectedWorkflow: async function () {
      this.clearFormChildData()
      this.indexFields = await this.$store.dispatch('common/getIndexFieldsForForm', this.selectedWorkflow.indexForm)
      this.indexFields.forEach((field, index) => {
        this.indexFields[index].readOnly = field?.filterValue === '' || field?.filterValue === null ? false : field.readOnly
      })
    },
    prevRoute: async function () {
      if (this.prevRoute.name === 'WorkflowReportConfig' || this.prevRoute.name === 'TheSavedWorkflowReport') {
        await this.$store.dispatch('reporting/populateWorkflowReportData')
        this.setReportValues()
      }
    },
  },
  created: async function () {
    this.$store.dispatch('common/getUsersWithAutoCompleted')
    this.$material.locale.dateFormat = this.$store.state.systemSettings.systemDateFormat
    this.clearFormChildData()
    this.$store.dispatch('reporting/resetReports')
    await this.$store.dispatch('reporting/populateWorkflowReportData')
  },
  methods: {
    addedAttributesClick() { this.showFieldDetailsDialog = true },
    buildPayload() {
      if (typeof this.fromDate !== 'string' && typeof this.toDate !== 'string') {
        this.fromDate = this.fromDate.toLocaleDateString()
        this.toDate = this.toDate.toLocaleDateString()
      }

      const payload = {
        ActionDateFrom: this.fromDate,
        ActionDateThrough: this.toDate,
        LastWFID: this.selectedWorkflow.value,
        UserIDs: this.selectedUser && this.selectedUser.value && this.selectedUser.value > 0 ? [this.selectedUser.value] : [],
        SearchFields: this.indexFieldFilters,
        DisplayColumnNames: this.selectedIndexFieldsForExtract,
      }

      return payload
    },
    buildPayloadForSave() {
      const object = {}
      if (this.indexFieldFilters) {
        this.indexFieldFilters.forEach(a => {
          if (Array.isArray(a.value)) {
            a.value = a.value.join(';')
          }
          object[a.name] = a.value
        })
      }
      const payload = this.editingWorkflowReport ? this.workflowReportValues : {}
      payload.actionDateFrom = this.fromDate
      payload.actionDateThrough = this.toDate
      payload.lastWFID = this.selectedWorkflow?.value ? this.selectedWorkflow.value : null
      payload.actionUserIDs = this.selectedUser && this.selectedUser.value && this.selectedUser.value > 0 ? [this.selectedUser.value] : []
      payload.searchFields = object
      payload.workflowReportID = this.editingWorkflowReport ? this.workflowReportValues.workflowReportID : 0

      return payload
    },
    clearFormChildData: function () {
      this.$store.dispatch('reporting/setIndexFieldFilters', [])
      this.$store.dispatch('reporting/setSelectedIndexFieldsForExtract', [])
    },
    createFavorite() {
      // TODO: This is really just a placeholder for now
    },
    exportReport: function (type) {
      this.exportType = type
      this.showExportFieldsDialog = true
    },
    generateReport: async function () {
      this.isLoading = true

      const payload = await this.buildPayload()
      await this.$store.dispatch('reporting/runWorkflowReport', payload)

      await this.$amplitude.trackEvent('Report Run', {reportType: 'Workflow'})

      this.isLoading = false
    },
    indexFieldFilerSubmitted() { this.showFieldDetailsDialog = false },
    async indexFieldSelectorSubmitted() {
      const payload = this.buildPayload()

      if (this.exportType === 'pdf') {
        this.$store.dispatch('reporting/generateWorkflowReportPDF', payload)
      } // Add other types (ex. CSV) here ...
      await this.$amplitude.trackEvent('Report Shared', {
        'reportType': 'Workflow',
      })

      this.showExportFieldsDialog = false
    },
    saveReport() {
      const payload = this.buildPayloadForSave()
      this.$store.dispatch('reporting/setWorkflowReportData', payload)
      this.$router.push({ name: 'WorkflowReportConfig' })
    },
    setReportValues() {
      this.fromDate = this.workflowReportValues.actionDateFrom.split('T')[0]
      this.toDate = this.workflowReportValues.actionDateThrough.split('T')[0]
      this.selectedWorkflow = this.workflows.find(a => a.value === this.workflowReportValues.lastWFID)
      this.selectedUser = this.users.find(a => a.value === this.workflowReportValues.actionUserIDs[0])
    },
  },
}
</script>

<style lang="scss" scoped>

  .retractedResultsGridDiv {
    max-width: calc(100vw - 365px);
  }

  .expandedResultsGridDiv {
    max-width: calc(100vw - 305px);
  }

  .added-attributes-button {
    padding-top: 20px;
  }

  .results-table-container {
    overflow-y: auto;
    height: calc(100vh - 386px);
  }

  .attribute-fields-dialog-window {
    max-height: 600px;
    width: 600px;
    overflow: auto;
  }

  .dialog-window {
    max-height: 600px;
    width: 400px;
    overflow: auto;
  }

  .alignRight {
    text-align: right;
  }

  .item-div {
    width: calc(25% - 45px);
  }

  .w100 {
    width: 100%;
  }

  .w25 {
    width: 25%;
    padding-right: 10px;
  }

  .w50 {
    width: 50%;
    padding-right: 10px;
  }
  #indexFieldFiler {
    padding: 15px;
  }
</style>
